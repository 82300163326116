import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_UNITY,
    DELETE_UNITY,
    UNITY_FORM_TOOGLE_LOADING,
    SET_UNITY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_UNITY,
} from "../constants";
import { formatUnitys } from "./settingsActionsUtils";

/* UNITY LIST */
export const fetchUnitys = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.unitys.list)
            .then((response) => {
                const unitys = formatUnitys(response.data);
                dispatch({
                    type: SET_UNITY_LIST,
                    payload: keyBy(unitys, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// UNITYS
export const createUnity = (newUnity) => {
    return async (dispatch) => {
        dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.unitys.create, newUnity)
            .then((response) => {
                const unity = formatUnitys(response.data);
                dispatch({ type: ADD_NEW_UNITY, payload: unity });
                dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Unidad creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateUnity = (updatedUnity) => {
    return async (dispatch) => {
        dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.unitys.edit}${updatedUnity && updatedUnity._id}`, updatedUnity)
            .then((response) => {
                const unity = formatUnitys(response.data);
                dispatch({ type: UPDATE_UNITY, payload: unity });
                dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Unidad actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteUnitys = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.unitys.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_UNITY, payload: Ids });
                dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Unidad eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: UNITY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
