import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ESTABLISHMENT,
    DELETE_ESTABLISHMENT,
    ESTABLISHMENT_FORM_TOOGLE_LOADING,
    SET_ESTABLISHMENT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ESTABLISHMENT,
} from "../constants";
import { formatEstablishments } from "./settingsActionsUtils";

/* ESTABLISHMENT LIST */
export const fetchEstablishments = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.establishments.list)
            .then((response) => {
                const establishments = formatEstablishments(response.data);
                dispatch({
                    type: SET_ESTABLISHMENT_LIST,
                    payload: keyBy(establishments, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ESTABLISHMENTS
export const createEstablishment = (newEstablishment) => {
    return async (dispatch) => {
        dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.establishments.create, newEstablishment)
            .then((response) => {
                const establishment = formatEstablishments(response.data);
                dispatch({ type: ADD_NEW_ESTABLISHMENT, payload: establishment });
                dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Establecimiento creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateEstablishment = (updatedEstablishment) => {
    return async (dispatch) => {
        dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.establishments.edit}${updatedEstablishment && updatedEstablishment._id}`, updatedEstablishment)
            .then((response) => {
                const establishment = formatEstablishments(response.data);
                dispatch({ type: UPDATE_ESTABLISHMENT, payload: establishment });
                dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Establecimiento actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteEstablishments = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.establishments.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ESTABLISHMENT, payload: Ids });
                dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Establecimiento eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ESTABLISHMENT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
