import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MATRIX,
    DELETE_MATRIX,
    MATRIX_FORM_TOOGLE_LOADING,
    SET_MATRIX_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MATRIX,
} from "../constants";
import { formatMatrixs } from "./settingsActionsUtils";

/* MATRIX LIST */
export const fetchMatrixs = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.matrixs.list)
            .then((response) => {
                const matrixs = formatMatrixs(response.data);
                dispatch({
                    type: SET_MATRIX_LIST,
                    payload: keyBy(matrixs, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MATRIXS
export const createMatrix = (newMatrix) => {
    return async (dispatch) => {
        dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.matrixs.create, newMatrix)
            .then((response) => {
                const matrix = formatMatrixs(response.data);
                dispatch({ type: ADD_NEW_MATRIX, payload: matrix });
                dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Matriz creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMatrix = (updatedMatrix) => {
    return async (dispatch) => {
        dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.matrixs.edit}${updatedMatrix && updatedMatrix._id}`, updatedMatrix)
            .then((response) => {
                const matrix = formatMatrixs(response.data);
                dispatch({ type: UPDATE_MATRIX, payload: matrix });
                dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Matriz actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMatrixs = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.matrixs.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MATRIX, payload: Ids });
                dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Matriz eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MATRIX_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
