import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SOCIETY,
    DELETE_SOCIETY,
    SOCIETY_FORM_TOOGLE_LOADING,
    SET_SOCIETY_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SOCIETY,
} from "../constants";
import { formatSocietys } from "./settingsActionsUtils";

/* SOCIETY LIST */
export const fetchSocietys = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.societys.list)
            .then((response) => {
                const societys = formatSocietys(response.data);
                dispatch({
                    type: SET_SOCIETY_LIST,
                    payload: keyBy(societys, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SOCIETYS
export const createSociety = (newSociety) => {
    return async (dispatch) => {
        dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.societys.create, newSociety)
            .then((response) => {
                const society = formatSocietys(response.data);
                dispatch({ type: ADD_NEW_SOCIETY, payload: society });
                dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Sociedad creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateSociety = (updatedSociety) => {
    return async (dispatch) => {
        dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.societys.edit}${updatedSociety && updatedSociety._id}`, updatedSociety)
            .then((response) => {
                const society = formatSocietys(response.data);
                dispatch({ type: UPDATE_SOCIETY, payload: society });
                dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Sociedad actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteSocietys = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.societys.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SOCIETY, payload: Ids });
                dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Sociedad eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SOCIETY_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
