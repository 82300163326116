import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_HOUR,
    DELETE_HOUR,
    HOUR_FORM_TOOGLE_LOADING,
    SET_HOUR_LIST,
    SHOW_NOTIFICATION,
    UPDATE_HOUR,
} from "../constants";
import { formatHours } from "./settingsActionsUtils";

/* HOUR LIST */
export const fetchHours = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.hours.list)
            .then((response) => {
                const hours = formatHours(response.data);
                dispatch({
                    type: SET_HOUR_LIST,
                    payload: keyBy(hours, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// HOURS
export const createHour = (newHour) => {
    return async (dispatch) => {
        dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.hours.create, newHour)
            .then((response) => {
                const hour = formatHours(response.data);
                dispatch({ type: ADD_NEW_HOUR, payload: hour });
                dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Hora creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateHour = (updatedHour) => {
    return async (dispatch) => {
        dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.hours.edit}${updatedHour && updatedHour._id}`, updatedHour)
            .then((response) => {
                const hour = formatHours(response.data);
                dispatch({ type: UPDATE_HOUR, payload: hour });
                dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hora actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteHours = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.hours.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_HOUR, payload: Ids });
                dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Hora eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: HOUR_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
