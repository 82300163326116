import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SAMPLINGPOINT,
    DELETE_SAMPLINGPOINT,
    SAMPLINGPOINT_FORM_TOOGLE_LOADING,
    SET_SAMPLINGPOINT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SAMPLINGPOINT,
} from "../constants";
import { formatSamplingpoints } from "./settingsActionsUtils";

/* SAMPLINGPOINT LIST */
export const fetchSamplingpoints = () => {
    return async (dispatch, state) => {
        const response = await axios
            .get(API.samplingpoints.list)
            .then((response) => {
                const samplingpoints = formatSamplingpoints(response.data, state);
                dispatch({
                    type: SET_SAMPLINGPOINT_LIST,
                    payload: keyBy(samplingpoints, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SAMPLINGPOINTS
export const createSamplingpoint = (newSamplingpoint) => {
    return async (dispatch, state) => {
        dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.samplingpoints.create, newSamplingpoint)
            .then((response) => {
                const samplingpoint = formatSamplingpoints(response.data, state);
                dispatch({ type: ADD_NEW_SAMPLINGPOINT, payload: samplingpoint });
                dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Punto de muestreo creado con éxito.",
                    },
                });
                return response && response;
            })
            .catch((err) => {
                dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateSamplingpoint = (updatedSamplingpoint) => {
    return async (dispatch, state) => {
        dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.samplingpoints.edit}${updatedSamplingpoint && updatedSamplingpoint._id}`, updatedSamplingpoint)
            .then((response) => {
                const samplingpoint = formatSamplingpoints(response.data, state);
                dispatch({ type: UPDATE_SAMPLINGPOINT, payload: samplingpoint });
                dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Punto de muestreo actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteSamplingpoints = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.samplingpoints.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SAMPLINGPOINT, payload: Ids });
                dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Punto de muestreo eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SAMPLINGPOINT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
