import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_BUSINESSALERT,
    DELETE_BUSINESSALERT,
    BUSINESSALERT_FORM_TOOGLE_LOADING,
    SET_BUSINESSALERT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_BUSINESSALERT,
} from "../constants";
import { formatBusinessAlerts } from "./settingsActionsUtils";

/* BUSINESSALERT LIST */
export const fetchBusinessAlerts = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.businessAlerts.list)
            .then((response) => {
                const businessAlerts = formatBusinessAlerts(response.data);
                dispatch({
                    type: SET_BUSINESSALERT_LIST,
                    payload: keyBy(businessAlerts, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// BUSINESSALERTS
export const createBusinessAlert = (newBusinessAlert) => {
    return async (dispatch) => {
        dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.businessAlerts.create, newBusinessAlert)
            .then((response) => {
                const businessAlert = formatBusinessAlerts(response.data);
                dispatch({ type: ADD_NEW_BUSINESSALERT, payload: businessAlert });
                dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Alerta creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateBusinessAlert = (updatedBusinessAlert) => {
    return async (dispatch) => {
        dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.businessAlerts.edit}${updatedBusinessAlert && updatedBusinessAlert._id}`, updatedBusinessAlert)
            .then((response) => {
                const businessAlert = formatBusinessAlerts(response.data);
                dispatch({ type: UPDATE_BUSINESSALERT, payload: businessAlert });
                dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Alerta actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteBusinessAlerts = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.businessAlerts.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_BUSINESSALERT, payload: Ids });
                dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Alerta eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUSINESSALERT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
