import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PROJECT,
    DELETE_PROJECT,
    PROJECT_FORM_TOOGLE_LOADING,
    SET_PROJECT_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PROJECT,
} from "../constants";
import { formatProjects } from "./settingsActionsUtils";

/* PROJECT LIST */
export const fetchProjects = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.projects.list)
            .then((response) => {
                const projects = formatProjects(response.data);
                dispatch({
                    type: SET_PROJECT_LIST,
                    payload: keyBy(projects, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PROJECTS
export const createProject = (newProject) => {
    return async (dispatch) => {
        dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.projects.create, newProject)
            .then((response) => {
                const project = formatProjects(response.data);
                dispatch({ type: ADD_NEW_PROJECT, payload: project });
                dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Proyecto creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateProject = (updatedProject) => {
    return async (dispatch) => {
        dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.projects.edit}${updatedProject && updatedProject._id}`, updatedProject)
            .then((response) => {
                const project = formatProjects(response.data);
                dispatch({ type: UPDATE_PROJECT, payload: project });
                dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Proyecto actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteProjects = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.projects.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PROJECT, payload: Ids });
                dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Proyecto eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PROJECT_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
