import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_LAB,
    DELETE_LAB,
    LAB_FORM_TOOGLE_LOADING,
    SET_LAB_LIST,
    SHOW_NOTIFICATION,
    UPDATE_LAB,
} from "../constants";
import { formatLabs } from "./settingsActionsUtils";

/* LAB LIST */
export const fetchLabs = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.labs.list)
            .then((response) => {
                const labs = formatLabs(response.data);
                dispatch({
                    type: SET_LAB_LIST,
                    payload: keyBy(labs, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// LABS
export const createLab = (newLab) => {
    return async (dispatch) => {
        dispatch({ type: LAB_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.labs.create, newLab)
            .then((response) => {
                const lab = formatLabs(response.data);
                dispatch({ type: ADD_NEW_LAB, payload: lab });
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Laboratorio creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateLab = (updatedLab) => {
    return async (dispatch) => {
        dispatch({ type: LAB_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.labs.edit}${updatedLab && updatedLab._id}`, updatedLab)
            .then((response) => {
                const lab = formatLabs(response.data);
                dispatch({ type: UPDATE_LAB, payload: lab });
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Laboratorio actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteLabs = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: LAB_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.labs.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_LAB, payload: Ids });
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Laboratorio eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const sendReport = (newLab) => {
    return async (dispatch) => {
        dispatch({ type: LAB_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.labs.mail, newLab)
            .then((response) => {
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Factura enviada por correo.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: LAB_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};