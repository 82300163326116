import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ADITIONALINFORMATION,
    DELETE_ADITIONALINFORMATION,
    ADITIONALINFORMATION_FORM_TOOGLE_LOADING,
    SET_ADITIONALINFORMATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ADITIONALINFORMATION,
} from "../constants";
import { formatAditionalInformations } from "./settingsActionsUtils";

/* ADITIONALINFORMATION LIST */
export const fetchAditionalInformations = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.aditionalInformations.list)
            .then((response) => {
                const aditionalInformations = formatAditionalInformations(response.data);
                dispatch({
                    type: SET_ADITIONALINFORMATION_LIST,
                    payload: keyBy(aditionalInformations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ADITIONALINFORMATIONS
export const createAditionalInformation = (newAditionalInformation) => {
    return async (dispatch) => {
        dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.aditionalInformations.create, newAditionalInformation)
            .then((response) => {
                const aditionalInformation = formatAditionalInformations(response.data);
                dispatch({ type: ADD_NEW_ADITIONALINFORMATION, payload: aditionalInformation });
                dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Informacion Adicional creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateAditionalInformation = (updatedAditionalInformation) => {
    return async (dispatch) => {
        dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.aditionalInformations.edit}${updatedAditionalInformation && updatedAditionalInformation._id}`, updatedAditionalInformation)
            .then((response) => {
                const aditionalInformation = formatAditionalInformations(response.data);
                dispatch({ type: UPDATE_ADITIONALINFORMATION, payload: aditionalInformation });
                dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Informacion Adicional actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteAditionalInformations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.aditionalInformations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ADITIONALINFORMATION, payload: Ids });
                dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Informacion Adicional eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ADITIONALINFORMATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
