import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_IVA,
    DELETE_IVA,
    IVA_FORM_TOOGLE_LOADING,
    SET_IVA_LIST,
    SHOW_NOTIFICATION,
    UPDATE_IVA,
} from "../constants";
import { formatIvas } from "./settingsActionsUtils";

/* IVA LIST */
export const fetchIvas = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.ivas.list)
            .then((response) => {
                const ivas = formatIvas(response.data);
                dispatch({
                    type: SET_IVA_LIST,
                    payload: keyBy(ivas, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// IVAS
export const createIva = (newIva) => {
    return async (dispatch) => {
        dispatch({ type: IVA_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.ivas.create, newIva)
            .then((response) => {
                const iva = formatIvas(response.data);
                dispatch({ type: ADD_NEW_IVA, payload: iva });
                dispatch({ type: IVA_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "IVA creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IVA_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateIva = (updatedIva) => {
    return async (dispatch) => {
        dispatch({ type: IVA_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.ivas.edit}${updatedIva && updatedIva._id}`, updatedIva)
            .then((response) => {
                const iva = formatIvas(response.data);
                dispatch({ type: UPDATE_IVA, payload: iva });
                dispatch({ type: IVA_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "IVA actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IVA_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteIvas = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: IVA_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.ivas.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_IVA, payload: Ids });
                dispatch({ type: IVA_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "IVA eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IVA_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
