import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_COLUMN,
    DELETE_COLUMN,
    COLUMN_FORM_TOOGLE_LOADING,
    SET_COLUMN_LIST,
    SHOW_NOTIFICATION,
    UPDATE_COLUMN,
} from "../constants";
import { formatColumns } from "./settingsActionsUtils";

/* COLUMN LIST */
export const fetchColumns = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.columns.list)
            .then((response) => {
                const columns = formatColumns(response.data);
                dispatch({
                    type: SET_COLUMN_LIST,
                    payload: keyBy(columns, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// COLUMNS
export const createColumn = (newColumn) => {
    return async (dispatch) => {
        dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.columns.create, newColumn)
            .then((response) => {
                const column = formatColumns(response.data);
                dispatch({ type: ADD_NEW_COLUMN, payload: column });
                dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Columna creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateColumn = (updatedColumn) => {
    return async (dispatch) => {
        dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.columns.edit}${updatedColumn && updatedColumn._id}`, updatedColumn)
            .then((response) => {
                const column = formatColumns(response.data);
                dispatch({ type: UPDATE_COLUMN, payload: column });
                dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Columna actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteColumns = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.columns.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_COLUMN, payload: Ids });
                dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Columna eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: COLUMN_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
