import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_SUGGESTION,
    DELETE_SUGGESTION,
    SUGGESTION_FORM_TOOGLE_LOADING,
    SET_SUGGESTION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_SUGGESTION,
} from "../constants";
import { formatSuggestions } from "./settingsActionsUtils";

/* SUGGESTION LIST */
export const fetchSuggestions = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.suggestions.list)
            .then((response) => {
                const suggestions = formatSuggestions(response.data);
                dispatch({
                    type: SET_SUGGESTION_LIST,
                    payload: keyBy(suggestions, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// SUGGESTIONS
export const createSuggestion = (newSuggestion) => {
    return async (dispatch) => {
        dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.suggestions.create, newSuggestion)
            .then((response) => {
                const suggestion = formatSuggestions(response.data);
                dispatch({ type: ADD_NEW_SUGGESTION, payload: suggestion });
                dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Sugerencia creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateSuggestion = (updatedSuggestion) => {
    return async (dispatch) => {
        dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.suggestions.edit}${updatedSuggestion && updatedSuggestion._id}`, updatedSuggestion)
            .then((response) => {
                const suggestion = formatSuggestions(response.data);
                dispatch({ type: UPDATE_SUGGESTION, payload: suggestion });
                dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Sugerencia actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteSuggestions = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.suggestions.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_SUGGESTION, payload: Ids });
                dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Sugerencia eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: SUGGESTION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
