import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_ROLE,
    DELETE_ROLE,
    ROLE_FORM_TOOGLE_LOADING,
    SET_ROLE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_ROLE,
} from "../constants";
import { formatRoles } from "./settingsActionsUtils";

/* ROLE LIST */
export const fetchRoles = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.roles.list)
            .then((response) => {
                const roles = formatRoles(response.data);
                dispatch({
                    type: SET_ROLE_LIST,
                    payload: keyBy(roles, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// ROLES
export const createRole = (newRole) => {
    return async (dispatch) => {
        dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.roles.create, newRole)
            .then((response) => {
                const role = formatRoles(response.data);
                dispatch({ type: ADD_NEW_ROLE, payload: role });
                dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Role creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateRole = (updatedRole) => {
    return async (dispatch) => {
        dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.roles.edit}${updatedRole && updatedRole._id}`, updatedRole)
            .then((response) => {
                const role = formatRoles(response.data);
                dispatch({ type: UPDATE_ROLE, payload: role });
                dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Role actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteRoles = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.roles.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_ROLE, payload: Ids });
                dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Role eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: ROLE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
