import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
  ADD_NEW_LEAVE,
  DELETE_LEAVE,
  LEAVE_FORM_TOOGLE_LOADING,
  SET_LEAVE_LIST,
  SHOW_NOTIFICATION,
  UPDATE_LEAVE,
} from "../constants";
import { formatLeave } from "./settingsActionsUtils";

/* LEAVE LIST */
export const fetchLeave = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.leave.list)
      .then((response) => {
        const leave = formatLeave(response.data);
        dispatch({
          type: SET_LEAVE_LIST,
          payload: keyBy(leave, "_id"),
        });
        return response;
      })
      .catch((err) => err);
    return response;
  };
};

// LEAVES
export const createLeave = (newLeave) => {
  return async (dispatch) => {
    dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.leave.create, newLeave)
      .then((response) => {
        const leave = formatLeave(response.data);
        dispatch({ type: ADD_NEW_LEAVE, payload: leave });
        dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Baja creada con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateLeave = (updatedLeave) => {
  return async (dispatch) => {
    dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.leave.edit}${updatedLeave?._id}`, updatedLeave)
      .then((response) => {
        const leave = formatLeave(response.data);
        dispatch({ type: UPDATE_LEAVE, payload: leave });
        dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Baja actualizada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteLeave = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.leave.delete}`, { data: Ids })
      .then((response) => {
        dispatch({ type: DELETE_LEAVE, payload: Ids });
        dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Baja eliminada.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: LEAVE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
