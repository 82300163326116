import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CONSULTINVOICE,
    DELETE_CONSULTINVOICE,
    CONSULTINVOICE_FORM_TOOGLE_LOADING,
    SET_CONSULTINVOICE_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CONSULTINVOICE,
} from "../constants";
import { formatConsultInvoices } from "./settingsActionsUtils";

/* CONSULTINVOICE LIST */
export const fetchConsultInvoices = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.consultInvoices.list)
            .then((response) => {
                const consultInvoices = formatConsultInvoices(response.data);
                dispatch({
                    type: SET_CONSULTINVOICE_LIST,
                    payload: keyBy(consultInvoices, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CONSULTINVOICES
export const createConsultInvoice = (newConsultInvoice) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.consultInvoices.create, newConsultInvoice)
            .then((response) => {
                const consultInvoice = formatConsultInvoices(response.data);
                dispatch({ type: ADD_NEW_CONSULTINVOICE, payload: consultInvoice });
                dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Facturas consultoría creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateConsultInvoice = (updatedConsultInvoice) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.consultInvoices.edit}${updatedConsultInvoice && updatedConsultInvoice._id}`, updatedConsultInvoice)
            .then((response) => {
                const consultInvoice = formatConsultInvoices(response.data);
                dispatch({ type: UPDATE_CONSULTINVOICE, payload: consultInvoice });
                dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Facturas consultoría actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteConsultInvoices = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.consultInvoices.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CONSULTINVOICE, payload: Ids });
                dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Facturas consultoría eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTINVOICE_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
