import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_PARAMETER,
    DELETE_PARAMETER,
    PARAMETER_FORM_TOOGLE_LOADING,
    SET_PARAMETER_LIST,
    SHOW_NOTIFICATION,
    UPDATE_PARAMETER,
} from "../constants";
import { formatParameters } from "./settingsActionsUtils";

/* PARAMETER LIST */
export const fetchParameters = () => {
    return async (dispatch, state) => {
        const response = await axios
            .get(API.parameters.list)
            .then((response) => {
                const parameters = formatParameters(response.data, state);
                dispatch({
                    type: SET_PARAMETER_LIST,
                    payload: keyBy(parameters, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// PARAMETERS
export const createParameter = (newParameter) => {
    return async (dispatch, state) => {
        dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.parameters.create, newParameter)
            .then((response) => {
                const parameter = formatParameters(response.data, state);
                dispatch({ type: ADD_NEW_PARAMETER, payload: parameter });
                dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Parámetro creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateParameter = (updatedParameter) => {
    return async (dispatch, state) => {
        dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.parameters.edit}${updatedParameter && updatedParameter._id}`, updatedParameter)
            .then((response) => {
                const parameter = formatParameters(response.data, state);
                dispatch({ type: UPDATE_PARAMETER, payload: parameter });
                dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parámetro actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteParameters = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.parameters.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_PARAMETER, payload: Ids });
                dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Parámetro eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: PARAMETER_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
