import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_CONSULTOR,
    DELETE_CONSULTOR,
    CONSULTOR_FORM_TOOGLE_LOADING,
    SET_CONSULTOR_LIST,
    SHOW_NOTIFICATION,
    UPDATE_CONSULTOR,
} from "../constants";
import { formatConsultors } from "./settingsActionsUtils";

/* CONSULTOR LIST */
export const fetchConsultors = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.consultors.list)
            .then((response) => {
                const consultors = formatConsultors(response.data);
                dispatch({
                    type: SET_CONSULTOR_LIST,
                    payload: keyBy(consultors, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// CONSULTORS
export const createConsultor = (newConsultor) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.consultors.create, newConsultor)
            .then((response) => {
                const consultor = formatConsultors(response.data);
                dispatch({ type: ADD_NEW_CONSULTOR, payload: consultor });
                dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Consultoría creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateConsultor = (updatedConsultor) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.consultors.edit}${updatedConsultor && updatedConsultor._id}`, updatedConsultor)
            .then((response) => {
                const consultor = formatConsultors(response.data);
                dispatch({ type: UPDATE_CONSULTOR, payload: consultor });
                dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Consultoría actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteConsultors = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.consultors.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_CONSULTOR, payload: Ids });
                dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Consultoría eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: CONSULTOR_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
