import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  singUps,
  singUpsForm,
  timeRequests,
  timeRequestsForm,
  holidayRequests,
  holidayRequestsForm,
  establishmentInfo,
  establishmentInfoForm,
  leaves,
  leavesForm,
  businesss,
  businesssForm,
  projects,
  projectsForm,
  activitys,
  activitysForm,
  hours,
  hoursForm,
  configurations,
  configurationsForm,
  establishments,
  establishmentsForm,
  suggestions,
  suggestionsForm,
  unitys,
  unitysForm,
  locations,
  locationsForm,
  parameters,
  parametersForm,
  legislations,
  legislationsForm,
  containers,
  containersForm,
  services,
  servicesForm,
  samplingpoints,
  samplingpointsForm,
  plannings,
  planningsForm,
  matrixs,
  matrixsForm,
  fees,
  feesForm,
  samplings,
  samplingsForm,
  columns,
  columnsForm,
  businessAlerts,
  businessAlertsForm,
  stocks,
  stocksForm,
  equipments,
  equipmentsForm,
  maintenances,
  maintenancesForm,
  societys,
  societysForm,
  labs,
  labsForm,
  consultors,
  consultorsForm,
  consultInvoicePlannings,
  consultInvoicePlanningsForm,
  consultInvoices,
  consultInvoicesForm,
  roles,
  rolesForm,
  ivas,
  ivasForm,
  aditionalInformations,
  aditionalInformationsForm,
} from "./Reducers";

export default combineReducers({
  aditionalInformations,
  aditionalInformationsForm,
  ivas,
  ivasForm,
  roles,
  rolesForm,
  consultInvoices,
  consultInvoicesForm,
  consultInvoicePlannings,
  consultInvoicePlanningsForm,
  consultors,
  consultorsForm,
  labs,
  labsForm,
  societys,
  societysForm,
  maintenances,
  maintenancesForm,
  equipments,
  equipmentsForm,
  stocks,
  stocksForm,
  businessAlerts,
  businessAlertsForm,
  columns,
  columnsForm,
  samplings,
  samplingsForm,
  fees,
  feesForm,
  matrixs,
  matrixsForm,
  plannings,
  planningsForm,
  samplingpoints,
  samplingpointsForm,
  services,
  servicesForm,
  containers,
  containersForm,
  legislations,
  legislationsForm,
  parameters,
  parametersForm,
  locations,
  locationsForm,
  unitys,
  unitysForm,
  suggestions,
  suggestionsForm,
  establishments,
  establishmentsForm,
  configurations,
  configurationsForm,
  hours,
  hoursForm,
  activitys,
  activitysForm,
  projects,
  projectsForm,
  businesss,
  businesssForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  singUps,
  singUpsForm,
  timeRequests,
  timeRequestsForm,
  holidayRequests,
  holidayRequestsForm,
  establishmentInfo,
  establishmentInfoForm,
  leaves,
  leavesForm,
});
