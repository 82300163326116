// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	aditionalInformations: {
    create: `/aditionalInformation`,
    edit: `/aditionalInformation/`,
    delete: `/aditionalInformation/`,
    list: `/aditionalInformation`,
  },
	ivas: {
    create: `/iva`,
    edit: `/iva/`,
    delete: `/iva/`,
    list: `/iva`,
  },
	roles: {
    create: `/role`,
    edit: `/role/`,
    delete: `/role/`,
    list: `/role`,
  },
  consultInvoices: {
    create: `/consultInvoice`,
    edit: `/consultInvoice/`,
    delete: `/consultInvoice/`,
    list: `/consultInvoice`,
  },
  consultInvoicePlannings: {
    create: `/consultInvoicePlanning`,
    edit: `/consultInvoicePlanning/`,
    delete: `/consultInvoicePlanning/`,
    list: `/consultInvoicePlanning`,
  },
  consultors: {
    create: `/consultor`,
    edit: `/consultor/`,
    delete: `/consultor/`,
    list: `/consultor`,
  },
  labs: {
    create: `/lab`,
    edit: `/lab/`,
    delete: `/lab/`,
    list: `/lab`,
    mail: `/lab/mail`,
  },
  societys: {
    create: `/society`,
    edit: `/society/`,
    delete: `/society/`,
    list: `/society`,
  },
  maintenances: {
    create: `/maintenance`,
    edit: `/maintenance/`,
    delete: `/maintenance/`,
    list: `/maintenance`,
  },
  equipments: {
    create: `/equipment`,
    edit: `/equipment/`,
    delete: `/equipment/`,
    list: `/equipment`,
  },
  stocks: {
    create: `/stock`,
    edit: `/stock/`,
    delete: `/stock/`,
    list: `/stock`,
  },
  businessAlerts: {
    create: `/businessAlert`,
    edit: `/businessAlert/`,
    delete: `/businessAlert/`,
    list: `/businessAlert`,
  },
  columns: {
    create: `/column`,
    edit: `/column/`,
    delete: `/column/`,
    list: `/column`,
  },
  samplings: {
    create: `/sampling`,
    edit: `/sampling/`,
    delete: `/sampling/`,
    list: `/sampling`,
    alerts: `/alerts`,
    poolData: `/poolData`,
    alertsMail: `/alertsMail`,
  },
  fees: {
    create: `/fee`,
    edit: `/fee/`,
    delete: `/fee/`,
    list: `/fee`,
  },
  matrixs: {
    create: `/matrix`,
    edit: `/matrix/`,
    delete: `/matrix/`,
    list: `/matrix`,
  },
  plannings: {
    create: `/planning`,
    edit: `/planning/`,
    delete: `/planning/`,
    list: `/planning`,
  },
  samplingpoints: {
    create: `/samplingpoint`,
    edit: `/samplingpoint/`,
    delete: `/samplingpoint/`,
    list: `/samplingpoint`,
  },
  services: {
    create: `/service`,
    edit: `/service/`,
    delete: `/service/`,
    list: `/service`,
  },
  containers: {
    create: `/container`,
    edit: `/container/`,
    delete: `/container/`,
    list: `/container`,
  },
  legislations: {
    create: `/legislation`,
    edit: `/legislation/`,
    delete: `/legislation/`,
    list: `/legislation`,
  },
  parameters: {
    create: `/parameter`,
    edit: `/parameter/`,
    delete: `/parameter/`,
    list: `/parameter`,
  },
  locations: {
    create: `/location`,
    edit: `/location/`,
    delete: `/location/`,
    list: `/location`,
  },
  unitys: {
    create: `/unity`,
    edit: `/unity/`,
    delete: `/unity/`,
    list: `/unity`,
  },
  suggestions: {
    create: `/suggestion`,
    edit: `/suggestion/`,
    delete: `/suggestion/`,
    list: `/suggestion`,
  },
  establishments: {
    create: `/establishment`,
    edit: `/establishment/`,
    delete: `/establishment/`,
    list: `/establishment`,
  },
  configurations: {
    create: `/configuration`,
    edit: `/configuration/`,
    delete: `/configuration/`,
    list: `/configuration`,
  },
  hours: {
    create: `/hour`,
    edit: `/hour/`,
    delete: `/hour/`,
    list: `/hour`,
  },
  activitys: {
    create: `/activity`,
    edit: `/activity/`,
    delete: `/activity/`,
    list: `/activity`,
  },
  projects: {
    create: `/project`,
    edit: `/project/`,
    delete: `/project/`,
    list: `/project`,
  },
  businesss: {
    create: `/business`,
    edit: `/business/`,
    delete: `/business/`,
    list: `/business`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
  singUps: {
    create: `/singUp`,
    edit: `/singUp/`,
    delete: `/singUp/`,
    list: `/singUp`,
    all: `/allSingUp`,
  },
  reasons: {
    create: `/reason`,
    edit: `/reason/`,
    delete: `/reason/`,
    list: `/reason`,
  },
  leave: {
    create: `/leave`,
    edit: `/leave/`,
    delete: `/leave/`,
    list: `/leave`,
  },
  establishmentInfo: {
    create: `/establishmentInfo`,
    edit: `/establishmentInfo/`,
    delete: `/establishmentInfo/`,
    list: `/establishmentInfo`,
  },
  timeRequests: {
    create: `/timeRequest`,
    edit: `/timeRequest/`,
    delete: `/timeRequest/`,
    list: `/timeRequest`,
  },
  holidayRequests: {
    create: `/holidayRequest`,
    edit: `/holidayRequest/`,
    delete: `/holidayRequest/`,
    list: `/holidayRequest`,
  },
};
